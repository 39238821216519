<script setup lang="ts">
import type { IPagination } from '~/types'
import type { Reservation } from '~/types/reservations'
import { useSanctumClient } from '#imports'
// import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const props = defineProps<{
  modelValue?: boolean
}>()

const emit = defineEmits(['update:model-value'])

const isOpen = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit('update:model-value', value),
})

const client = useSanctumClient()

const formData = reactive({
  query: undefined,
})

const columns = [
  {
    key: 'actions',
    class: 'w-10',
  },
  {
    label: '#',
    key: 'id',
    class: 'w-20',
  },
  {
    label: 'Confirmation Nº',
    key: 'confirmationNumber',
    class: 'w-40',
  },
  {
    label: 'Certificate Nº',
    key: 'certificateNumber',
    class: 'w-40',
  },
  {
    label: 'Guests',
    key: 'guest',
    class: 'w-fit',
  },
]

// const breakpoints = useBreakpoints(breakpointsTailwind)
// const smallerThanMd = breakpoints.smaller('md')

const rows = ref([])
const pageCount = ref(15)
const isLoading = ref(false)

const pagination = reactive<IPagination>({
  page: 1,
  currentPage: 1,
  total: 0,
  perPage: 15,
  lastPage: 1,
})

interface Response {
  data: Reservation[]
  meta: IPagination
}

async function search() {
  if (!formData.query) {
    return
  }
  isLoading.value = true
  const { data, meta } = await client<Response>('/api/v1/reservations/search', {
    params: {
      query: formData.query,
      _limit: pageCount.value,
      page: pagination.page,
    },
  })
  Object.assign(pagination, meta)
  isLoading.value = false
  rows.value = data
}

watchEffect(() => {
  if (pageCount.value || isOpen.value) {
    search()
  }

  if (pagination.page !== pagination.currentPage) {
    search()
  }
})

const isNavOpen = ref(false)
const currentReservation = ref<Reservation | null>(null)
async function goTo(item: Reservation) {
  isNavOpen.value = true
  currentReservation.value = item
  const { ready } = useTimeout(1500, { controls: true })
  if (!ready.value) {
    navigateTo(`/reservations/${item.id}/edit?search=${useDateId()}`, { replace: true })
    currentReservation.value = null
    isOpen.value = false
    isNavOpen.value = false
  }
}
</script>

<template>
  <USlideover
    v-model="isOpen" :ui="{
      width: 'w-screen max-w-3xl',
    }"
  >
    <UCard
      :ui="{
        body: {
          base: 'flex-1',
          padding: 'sm:px-6 sm:py-3',
        },
        footer: {
          base: 'min-h-14 bg-white absolute bottom-0 w-full',
        },
        ring: '',
        divide: 'flex-1 divide-y divide-gray-100 dark:divide-gray-800',
      }"
    >
      <template #header>
        <div class="flex items-center justify-between gap-2">
          <UInput
            v-model="formData.query"
            type="search"
            placeholder="Search..."
            class="flex-1"
            :ui="{ input: { base: 'rounded-full' } }"
            @keydown.enter="search"
          />
          <UButton
            color="gray"
            variant="ghost"
            size="sm"
            icon="i-heroicons-x-mark-20-solid"
            class="flex end-5 top-5 z-10"
            square
            padded
            @click="isOpen = false"
          />
        </div>
      </template>

      <div class="flex justify-between items-center w-full pb-3">
        <div class="flex items-center gap-1.5">
          <span class="text-xs leading-5">Rows per page:</span>
          <USelect
            v-model="pageCount"
            :options="[10, 15, 25, 40, 50]"
            class="me-2 w-20"
            size="xs"
          />
        </div>
      </div>

      <div class="flex-1 h-full overflow-auto">
        <UTable
          :rows="rows"
          :columns="columns" :loading="isLoading"
          :ui="{
            base: 'relative',
            wrapper: 'grow',
            thead: 'sticky top-0 bg-white',
            tr: {
              base: 'hover:bg-kovay-50 dark:hover:bg-gray-800 hover:cursor-pointer',
            },
            th: {
              base: 'sticky top-0 bg-white dark:bg-gray-900',
              padding: 'px-1 py-2.5 text-xs font-medium',
            },
            td: {
              padding: 'px-1 py-1.5 text-xs',
            },
          }"
          :empty-state="{ icon: 'i-heroicons-circle-stack-20-solid', label: 'Write something to search.' }"
          class="w-full"
          @select="goTo"
        >
          <template #actions-data="{ row }">
            <div class="flex gap-2">
              <UButton
                icon="lucide:square-arrow-left"
                size="2xs"
                variant="ghost"
                square
                aria-label="Edit"
                loading-icon="lucide:loader"
                :loading="currentReservation?.id === row.id && isNavOpen"
                @click="goTo(row)"
              />
            </div>
          </template>
          <template #guest-data="{ row }">
            <div
              v-for="g in row.guests"
              :key="g"
              class="flex flex-row gap-2 items-center font-sans  whitespace-pre-line"
              :class="{ 'italic font-semibold': g.isPrincipal, 'text-gray-400': !g.isPrincipal }"
            >
              <span>{{ g.name }}</span>
              <UTooltip
                v-if="g.isPrincipal"
                text="Principal Guest"
              >
                <UIcon
                  name="lucide:speech"
                  class="text-green-500 w-4 h-4"
                  size="sm"
                />
              </UTooltip>
            </div>
          </template>
        </UTable>
      </div>

      <template #footer>
        <div
          v-if="pagination.total > 0"
          class="flex flex-wrap justify-between items-center"
        >
          <div>
            <span class="text-xs leading-5">
              Showing
              <span class="font-medium">{{ pagination.from }}</span>
              to
              <span class="font-medium">{{ pagination.to }}</span>
              of
              <span class="font-medium">{{ pagination.total }}</span>
              results
            </span>
          </div>

          <UPagination
            v-model="pagination.page"
            size="xs"
            :page-count="pagination.perPage"
            :total="pagination.total"
            :ui="{
              wrapper: 'flex items-center gap-1',
              rounded: '!rounded-full min-w-[28px] justify-center',
              default: {
                activeButton: {
                  variant: 'outline',
                },
              },
            }"
          />
        </div>
      </template>
    </UCard>
  </USlideover>
</template>

<style scoped></style>
