<script setup lang="ts">
import Toaster from '@/components/ui/toast/Toaster.vue'
import { useOnline } from '@vueuse/core'
import { links } from '~/config/sidebar'

const { $pwa } = useNuxtApp()

// add lang attribute to html tag
useHead({
  htmlAttrs: {
    lang: 'en',
  },
})

const isCollapsed = ref(true)
const online = reactive(useOnline())
const toast = useToast()

const initialLoad = ref(true)

watch(
  () => online.value,
  (value) => {
    if (initialLoad.value) {
      initialLoad.value = false
      return // Skip the initial load
    }

    const status = value
      ? { title: 'You are online', description: 'You are connected to the internet' }
      : { title: 'You are offline', description: 'You are not connected to the internet' }
    toast.add({
      ...status,
      icon: 'lucide:radio-tower',
    })
  },
  { immediate: true },
)
</script>

<template>
  <div class="flex min-h-screen w-full flex-col bg-muted/40">
    <NuxtPwaManifest />
    <div
      class="flex flex-col sm:gap-4 sm:py-4"
      :class="[isCollapsed ? 'sm:pl-14' : 'sm:pl-56']"
    >
      <SideBar
        :links="links"
        :is-collapsed="isCollapsed"
      />

      <TheHeading />
      <main class="flex flex-1 gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
        <div class="grow">
          <slot />
        </div>
      </main>
    </div>
    <Toaster />
    <!-- ClientOnly>
      <ContentSearch />
    </ClientOnly -->
    <UNotifications />
    <UModals />
    <!-- LayoutThePresenceIndicator / -->

    <div v-show="$pwa.needRefresh">
      <span>
        New content available, click on reload button to update.
      </span>

      <button @click="$pwa.updateServiceWorker()">
        Reload
      </button>
    </div>
  </div>
</template>

<style scoped></style>
